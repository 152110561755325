<!-- eslint-disable no-undef -->
<template>
  <NavbarPart />
  <!-- <CarouselPart  />
  <ContentPart /> -->
  <div>
    <div class="container mx-auto px-10 w-full py-10">
      <div>
        <section id="information" class="prose w-full max-w-full mb-10">
          <h2 class="text-black text-center">Request Account Deletion</h2>
          <div class="divider mt-8"></div>
          <!-- Insert your privacy policy content here -->
          <div class="w-full max-w-xl mx-auto">
            <form class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4" ref="reqDelete" @submit.prevent="handleFormSubmit">
              <h3 class="text-black mb-7">Provide details about your profile in SpodBaca</h3>
              <div class="mb-4">
                <label class="block text-gray-700 text-sm font-bold mb-2" for="email">
                  Email address associated with your SpodBaca account
                </label>
                <div class="relative">
                  <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                    <svg class="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 16">
                      <path d="m10.036 8.278 9.258-7.79A1.979 1.979 0 0 0 18 0H2A1.987 1.987 0 0 0 .641.541l9.395 7.737Z"/>
                      <path d="M11.241 9.817c-.36.275-.801.425-1.255.427-.428 0-.845-.138-1.187-.395L0 2.6V14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2.5l-8.759 7.317Z"/>
                    </svg>
                  </div>
                  <input type="text" id="email-address-icon" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5" placeholder="name@email.com" v-model="email" required>
                </div>
                <p class="text-error">{{ errorEmail }}</p>
              </div>
              <div class="mb-18">
                <label class="block text-gray-700 text-sm font-bold mb-2" for="username">
                  Display Name (Option)
                </label>
                <input class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" id="name" type="text" placeholder="Name" v-model="name">
              </div>
              <h3 class="text-black mb-7">How can we get in touch with you?</h3>
              <div class="mb-4">
                <label class="block text-gray-700 text-sm font-bold mb-2" for="email">
                  Email
                </label>
                <div class="relative">
                  <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                    <svg class="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 16">
                      <path d="m10.036 8.278 9.258-7.79A1.979 1.979 0 0 0 18 0H2A1.987 1.987 0 0 0 .641.541l9.395 7.737Z"/>
                      <path d="M11.241 9.817c-.36.275-.801.425-1.255.427-.428 0-.845-.138-1.187-.395L0 2.6V14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2.5l-8.759 7.317Z"/>
                    </svg>
                  </div>
                  <input type="text" id="email-address" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5" placeholder="name@email.com" v-model="contactemail"  required>
                </div>
                <p class="text-error">{{ errorContactEmail }}</p>
              </div>
              <div class="mb-4">
                <label for="request" class="block text-gray-700 text-sm font-bold mb-2">Please share some additional information about your request.</label>
                <textarea id="request" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500" v-model="request"></textarea>
              </div>
              <div class="mb-4">
                <h4 class="text-success">{{ message }}</h4>
              </div>
              <div class="flex items-center justify-between">
                <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                  Submit
                </button>
              </div>
            </form>
          </div>
          <!-- <h2 class="text-lg font-semibold mb-4">10. Governing Law</h2> -->
        </section>
      </div>
    </div>
  </div>
  <FooterPart />
</template>

<script>
import NavbarPart from '../components/Navbar.vue'
import FooterPart from '../components/Footer.vue'
import { watchEffect } from 'vue'
// import {emailValidator, required} from '@core/utils/validation'
// import {required, email} from '@vuelidate/validators'
export default {
  name: 'App',
  components: {
    NavbarPart,
    // CarouselPart,
    // ContentPart,
    FooterPart,
  },
  data() {
    return {
      email : '',
      name : '',
      contactemail : '',
      request : '',
      message : '',
      errorEmail: '',
      errorContactEmail: '',
      unwatch : watchEffect(() => {})
      // reg: /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
    }
  },
  methods: {
    isEmailValid: function() {
      return (this.email == "")? "" : (this.reg.test(this.email)) ? 'has-success' : 'has-error';
    },
    handleFormSubmit () {
      // const unwatch = watchEffect(() => {})
      this.email = '',
      this.name = '',
      this.contactemail = '',
      this.request = '',
      this.message = 'Success Request Deleted Account'
      // unwatch(email)
      // console.log(event);
    },
    validateEmail(email) {
      if (/^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        this.errorEmail = ''
      } else {
        this.errorEmail = 'Invalid Email'
      }
    },
    validateContactEmail(email) {
      if (/^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        this.errorContactEmail = ''
      } else {
        this.errorContactEmail = 'Invalid Email'
      }
    }
  },
  watch: {
    // email(value){
    //   this.email = value;
    //   this.validateEmail(value);
    // },
    // contactemail(value){
    //   this.contactemail = value;
    //   this.validateContactEmail(value);
    // },
    // errorEmail:'',
    // errorContactEmail:''
  },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
#app {
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;
}
html {
  scroll-behavior: smooth;
}
</style>
