<template>
  <div class="mb-5">
    <div class="carousel w-full">
      <swiper :slides-per-view="1" :modules="modules" :pagination="{ clickable: true }" class="mySwiper">
        <swiper-slide v-for="sliders in slider" :key="sliders.order" class="m-0">
          <div
            class="overlay py-0 w-full bg-no-repeat bg-cover object-center object-cover"
            v-bind:style="{ 'background-image': 'url(' + sliders.image + ')' }"
          >
            <div class="container mx-auto">
              <div
                class="flex h-fit py-40 justify-center flex-col sm:flex-row md:flex-row lg:flex-row xl:flex-row 2xl:flex-row items-center"
              >
                <div
                  class="z-50 text-center sm:text-left md:text-left lg:text-left xl:text-left 2xl:text-left flex-none w-full sm:w-2/4 md:w-2/4 prose px-5 mb-2"
                >
                  <h1 class="text-[#FFB703] font-bold mb-2 drop-shadow-lg shadow-black">
                    {{ company.total_library }} Buku Gratis
                  </h1>
                  <p class="m-0 text-white font-medium">Tersedia di SpodBaca Digital</p>
                  <div class="block my-4 mx-0">
                    <img
                      class="mx-auto sm:mx-0 md:mx-0 lg:mx-0 xl:mx-0 2xl:mx-0"
                      width="150"
                      :src="company.thumbnail"
                    />
                  </div>
                  <h3 class="mb-0 sm:mb-8 md:mb-8 lg:mb-8 xl:mb-8 2xl:mb-8 mt-4 text-white font-normal">
                    {{ company.name }}
                  </h3>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.titikbacadigital"
                    style="text-decoration: none; color: inherit"
                  >
                    <button
                      class="hidden sm:inline-flex md:inline-flex lg:inline-flex xl:inline-flex 2xl:inline-flex btn btn-ghost capitalize rounded-full bg-[#00BB8D] text-white px-8 sm:px-4 md:px-8 lg:px-8 xl:px-8 2xl:px-8 shadow-lg shadow-slate-700/40"
                    >
                      <span><img class="m-0" src="../assets/icons/google-play.svg" /></span>
                      <span class="inline-block text-base sm:text-sm ml-3 font-semibold">Download Sekarang</span>
                    </button>
                  </a>
                </div>
                <div class="z-50 text-center flex-none w-full sm:w-2/4 md:w-2/4 px-5 mx-auto">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.titikbacadigital"
                    style="text-decoration: none; color: inherit"
                  >
                    <button
                      class="mt-6 inline-flex sm:hidden md:hidden lg:hidden xl:hidden 2xl:hidden btn btn-ghost capitalize rounded-full bg-[#00BB8D] text-white px-8 sm:px-4 md:px-8 lg:px-8 xl:px-8 2xl:px-8 shadow-lg shadow-slate-700/40"
                    >
                      <span><img class="m-0" src="../assets/icons/google-play.svg" /></span>
                      <span class="inline-block text-base sm:text-sm ml-3 font-semibold">Download Sekarang</span>
                    </button>
                  </a>
                  <a
                    href="#info"
                    style="text-decoration: none; color: inherit"
                  >
                    <button
                      class="mt-6 inline-flex sm:hidden md:hidden lg:hidden xl:hidden 2xl:hidden btn btn-ghost capitalize rounded-full bg-transparent text-white border-3 border-white px-8 sm:px-4 md:px-8 lg:px-8 xl:px-8 2xl:px-8 shadow-lg shadow-slate-700/40"
                    >
                      <span><img class="m-0" src="../assets/icons/arrow-down.svg" /></span>
                      <span class="inline-block text-base sm:text-sm ml-3 font-semibold">Baca Informasi Instansi</span>
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import SliderServices from '@/services/SliderServices'

export default {
  props: {
    company: {
      type: Object,
      default: () => {},
    },
  },
  name: 'CarouselPart',
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      modules: [Pagination],
      settings: {
        dots: true,
        infinite: true,
        rows: 1,
        initialSlide: 2,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        swipeToSlide: true,
        arrows: true,
      },
      slider: {},
    }
  },
  watch: {
    company: {
      handler() {
        this.getSlider()
      },
    },
  },
  methods: {
    async getSlider() {
      await SliderServices.get(this.company.uuid).then(
        ({ data }) => {
          this.slider = data.data
        },
        err => {
          console.error(err)
        },
      )
      console.log(this.slider)
    },
  },
}
</script>

<style scoped>
.swiper {
  width: 100%;
  height: 100%;
}

.overlay::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0 0 0 / 40%);
}
</style>
