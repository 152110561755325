<template>
  <div class="px-8 md:px-14 lg:px-14 xl:px-14 2xl:px-14">
    <div class="navbar bg-base-100">
      <div class="flex-1">
        <router-link to="/">
          <a class="normal-case"><img src="../assets/images/logos/SpodBaca-Color.png" style="height: 58px;" /></a>
        </router-link>
      </div>
      <!-- <div class="flex-none">
        <ul class="menu menu-horizontal p-0">
          <li><a>Deskripsi</a></li>
          <li><a>Lokasi</a></li>
          <li><a>Jumlah Kunjugan</a></li>
          <li tabindex="0">
            <a>
              Info
              <svg
                class="fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
              >
                <path
                  d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"
                />
              </svg>
            </a>
            <ul class="p-2 bg-base-100">
              <li><a>Submenu 1</a></li>
              <li><a>Submenu 2</a></li>
            </ul>
          </li>
        </ul>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavbarPart',
}
</script>

<style scoped></style>
