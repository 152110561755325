<template>
  <div>
    <div class="w-full max-w-full px-5 py-10 h-full mx-0 text-center prose px-5 md:px-10 lg:px-10 xl:px-10 2xl:px-10">
      <h1 class="text-[#746D7D] font-bold m-0 leading-normal">Halaman Tidak Ditemukan :(</h1>
      <div>
        <img class="text-center min-w-[300px] px-5 mx-auto mb-10" src="../../assets/images/error-vector/error-3.svg" />
      </div>
      <div class="my-8">
        <p class="mb-0 text-#746D7D font-medium">Sepertinya Halaman Yang Sedang Anda cari Rusak Atau Tidak ada</p>
        <p class="m-0 text-#746D7D font-medium">
          Coba kembali ke <a href="" class="text-[#00BB8D]">Halaman utama?</a>. Apabila anda merasa ini bug tolong
          laporkan kepada kami
        </p>
      </div>
    </div>
  </div>
</template>
