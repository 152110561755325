<template>
  <NavbarPart />
  <CarouselPart :company="company" />
  <ContentPart :company="company" />
  <FooterPart />
</template>

<script>
import NavbarPart from '../components/Navbar.vue'
import CarouselPart from '../components/Carousel.vue'
import ContentPart from '../components/Content.vue'
import FooterPart from '../components/Footer.vue'
import { onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import CompanyServices from '../services/CompanyServices'

export default {
  name: 'App',
  components: {
    NavbarPart,
    CarouselPart,
    ContentPart,
    FooterPart,
  },
  setup() {
    const company = ref({
      name: '',
      information: [],
      layouts: [],
    })
    const route = useRoute()
    const router = useRouter()
    const getCompany = async () => {
      await CompanyServices.get(route.params.uuid).then(
        ({ data }) => {
          // bankIcon.value = data.data
          company.value = data.data
        },
        err => {
          console.log(err.code)
          if (err.code === 'ERR_BAD_REQUEST') {
            router.push('/not-found')
          }
        },
      )
    }
    onMounted(() => {
      getCompany()
    })

    return {
      getCompany,
      company,
    }
  },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
#app {
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;
}
html {
  scroll-behavior: smooth;
}
</style>
