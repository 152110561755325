<template>
  <div>
    <div class="bg-footer">
      <footer class="container mx-auto px-5">
        <div class="pt-6">
          <img src="../assets/images/logos/SpodBaca-White-mini.png" class="min-w-10 w-56" />
        </div>
        <!-- <div class="divider"></div>
        <div class="grid grid-cols-2 md:grid-cols-3 mt-8">
          <div class="text-white">
            <span class="block footer-title opacity-100 normal-case mb-8 text-white">Perusahaan</span>
            <a class="block link link-hover mb-2">Tentang</a>
            <a class="block link link-hover mb-2">Produk</a>
            <a class="block link link-hover mb-2">Blog</a>
          </div>
          <div class="text-white">
            <span class="block footer-title opacity-100 normal-case mb-8 text-white">Hubungan Kami</span>
            <a class="block link link-hover mb-2">Bantuan</a>
            <a class="block link link-hover mb-2">Lokasi Kami</a>
          </div>
          <div class="text-white mt-8 md:mt-0 lg:mt-0 xl:mt-0 2xl:mt-0">
            <span class="block footer-title opacity-100 normal-case mb-8 text-white">Cari Tau Berita Terbaru</span>
            <div class="flex flex-row justify-start items-center">
              <div class="mr-5">
                <a class="link"><img src="../assets/icons/twitter.svg" /></a>
              </div>
              <div class="mr-5">
                <a class="link"><img src="../assets/icons/facebook.svg" /></a>
              </div>
              <div class="mr-5">
                <a class="link"><img src="../assets/icons/instagram.svg" /></a>
              </div>
              <div class="mr-5">
                <a class="link"><img src="../assets/icons/youtube.svg" /></a>
              </div>
            </div>
          </div>
        </div> -->
        <div class="divider mt-8"></div>
        <div
          class="flex flex-col sm:flex-col md:flex-row lg:flex-row xl:flex-row 2xl:flex-row items-start md:items-center lg:items-center xl:items-center 2xl:items-center"
        >
          <div class="flex-none w-full md:w-2/4 prose">
            <span class="text-white hidden md:block lg:block xl:block 2xl:block mb-2 mr-auto text-sm"
              >© SpodBaca Digital 2023
            </span>
          </div>
          <div class="flex-none w-full md:w-2/4 prose text-white">
            <div
              class="prose flex flex-col sm:flex-col md:flex-row lg:flex-row xl:flex-row 2xl:flex-row justify-between items-start md:items-center lg:items-center xl:items-center 2xl:items-center"
            >
              <a
                class="text-white block link link-hover mb-3 mr-auto text-base md:text-sm lg:text-sm xl:text-sm 2xl:text-sm"
                >
                <router-link to="/privacy-policy" class="text-white block link link-hover">Kebijakan Privasi</router-link>
                </a
              >
              <a
                class="text-white block link link-hover mb-3 mr-auto text-base md:text-sm lg:text-sm xl:text-sm 2xl:text-sm"
                >Syarat dan Ketentuan</a
              >
              <a
                class="text-white block link link-hover mb-3 mr-auto text-base md:text-sm lg:text-sm xl:text-sm 2xl:text-sm"
                >Pengaturan Cookie</a
              >
              <a
                class="text-white block link link-hover mb-3 mr-auto text-base md:text-sm lg:text-sm xl:text-sm 2xl:text-sm"
                >Keamanan</a
              >
            </div>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterPart',
  data() {
    return {}
  },
  methods: {},
}
</script>

<style scoped>
.bg-footer {
  background: rgb(4, 130, 117);
  background: linear-gradient(156deg, rgb(120, 4, 130) 0%, rgb(53, 16, 78) 100%);
}

.divider:before,
.divider:after {
  background-color: #ffffff69 !important;
  opacity: 0.8 !important;
  height: 0.02rem !important;
}
</style>
