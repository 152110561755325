<template>
  <NavbarPart />
  <!-- <CarouselPart  />
  <ContentPart /> -->
  <div>
    <div class="container mx-auto px-10 w-full py-10">
      <div>
        <section id="information" class="prose w-full max-w-full mb-10">
          <h2 class="text-black">Privacy Policy</h2>
          <div class="divider mt-8"></div>
          <!-- Insert your privacy policy content here -->
          <p>
            Effective Date: 07 June 2023
          </p>
          <h2 class="text-lg font-semibold mb-4">1. Introduction</h2>
          <p>
            Welcome to SpodBaca Digital! At SpodBaca Digital, we are committed to protecting the privacy of our users. This Privacy Policy outlines our practices concerning the collection, use, and sharing of your personal information when you use our mobile applications (referred to as "Apps"). By using our Apps, you agree to the terms and practices described in this Privacy Policy.
          </p>
          <h2 class="text-lg font-semibold mb-4">2. Information We Collect</h2>
          <p>
            a. <strong> Information You Provide:</strong> We may collect information that you provide directly when you use our Apps, such as when you create an account, make a purchase, contact customer support, or submit feedback. This may include your name, email address, payment information, and any other information you choose to provide.<br/>
          <!-- </p>
          <p> -->
            b. <strong> Automatically Collected Information:</strong> When you use our Apps, we may automatically collect certain information, such as your device type, operating system, unique device identifiers, IP address, usage data, and app analytics.<br/>
          <!-- </p>
          <p> -->
            c. <strong> Location Information:</strong> With your consent, we may collect precise location information from your mobile device. You can opt out of providing this information through your device settings.<br/>
          </p>
          <h2 class="text-lg font-semibold mb-4">3. How We Use Your Information</h2>
          <p>
            We use the information we collect for various purposes, including:
            <br/>
            a. <strong>Providing and Improving our Apps:</strong>  We use your information to deliver and enhance our Apps' functionality, personalize your experience, and develop new features and offerings.
            <br/>
            b. <strong>Customer Support:</strong>  We may use your information to respond to your inquiries and provide customer support.
            <br/>
            c. <strong>Communication:</strong>  We may use your email address to send you updates, promotional offers, and important information about our Apps. You can opt out of receiving marketing communications at any time.
            <br>
            d. <strong>Analytics:</strong> We use data analytics to understand how our Apps are used and to improve their performance and user experience.
          </p>
          <h2 class="text-lg font-semibold mb-4">4. Sharing Your Information</h2>
          <p>
            We may share your information in the following circumstances:
            <br>
            a. <strong>With Third-Party Service Providers:</strong> We may share your information with trusted third-party service providers who assist us in operating our Apps, such as hosting, data analysis, and customer support.
            <br>
            b. <strong>Legal Compliance:</strong> We may disclose your information when required by law or in response to legal requests.
            <br>
            c. <strong>Business Transfers:</strong> If SpodBaca Digital is involved in a merger, acquisition, or sale of assets, your information may be transferred as part of that transaction.
          </p>
          <h2 class="text-lg font-semibold mb-4">5. Data Security</h2>
          <p>
            We take data security seriously and employ reasonable measures to protect your information from unauthorized access, disclosure, alteration, or destruction. However, no data transmission over the internet or stored on electronic systems can be guaranteed to be 100% secure.
          </p>
          <h2 class="text-lg font-semibold mb-4">6. Children's Privacy</h2>
          <p>
            Our Apps are not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If you believe that a child has provided us with their personal information without parental consent, please contact us, and we will promptly delete the information.
          </p>
          <h2 class="text-lg font-semibold mb-4">7. Your Choices</h2>
          <p>
            You have the following choices regarding your information:
            <br>
            a. <strong>Access and Update:</strong> You can access and update the information you have provided through our Apps by logging into your account settings.
            <br>
            b. <strong>Delete Account:</strong> You can request the deletion of your account by contacting us.
            <br>
            c. <strong>Marketing Communications:</strong> You can opt out of receiving marketing communications by following the instructions provided in those communications or contacting us.
          </p>
          <h2 class="text-lg font-semibold mb-4">8. Changes to this Privacy Policy</h2>
          <p>
            We may update this Privacy Policy to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will notify you of any changes by posting the updated Privacy Policy on our website or through the Apps. Please review this Privacy Policy periodically for any updates.
          </p>
          <h2 class="text-lg font-semibold mb-4">9. Contact Us</h2>
          <p>
            If you have any questions, concerns, or requests related to this Privacy Policy or your personal information, please contact us at:

            spodbaca@gmail.com
          </p>
          <!-- <h2 class="text-lg font-semibold mb-4">10. Governing Law</h2> -->
        </section>
      </div>
    </div>
  </div>
  <FooterPart />
</template>

<script>
import NavbarPart from '../components/Navbar.vue'
// import CarouselPart from '../components/Carousel.vue'
// import ContentPart from '../components/Content.vue'
import FooterPart from '../components/Footer.vue'
// import { onMounted, ref } from 'vue'
// import { useRoute, useRouter } from 'vue-router'

export default {
  name: 'App',
  components: {
    NavbarPart,
    // CarouselPart,
    // ContentPart,
    FooterPart,
  },
  data() {
    return {}
  },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
#app {
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;
}
html {
  scroll-behavior: smooth;
}
</style>
